import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import H2L from 'components/typography/h2'
import Button from 'components/button/button'
import { graphql, Link } from 'gatsby'
import Form from 'components/forms/contact'
import { Helmet } from 'react-helmet'
import LocationsSection from 'components/locations-section'
import Layout from 'components/layout'
import Content from 'components/content'
import Section from 'components/section'
import { Grid, Column } from 'components/grid'

const breadcrumbsSchema = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  'itemListElement': [
    {
      '@type': 'ListItem',
      'position': 1,
      'name': 'Hutson Inc',
      'item': 'https://www.hutsoninc.com/',
    },
    {
      '@type': 'ListItem',
      'position': 2,
      'name': 'Contact',
      'item': 'https://www.hutsoninc.com/contact/',
    },
  ],
})

const ContactPage = props => {
  const {
    data: {
      allSanityLocation: { totalCount: locationCount },
      heroImage,
    },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Contact Us | Hutson Inc</title>
        <meta
          name='description'
          content={`Trying to get in touch with someone at Hutson? Fill out a contact form to reach our sales, service, parts, or other departments at any of our ${locationCount} locations.`}
        />
        <script type='application/ld+json'>{breadcrumbsSchema}</script>
      </Helmet>

      <HeroImage title='Contact Us' image={heroImage} />

      <Content>
        <H2L>Contact Us</H2L>
        <Form />

        <Section>
          <H2L>Other Forms</H2L>
          <Grid>
            <StyledColumn columns='1/3'>
              <Button as={ButtonLink} to='/sponsorship/' color='green' ghost>
                Sponsorship Request
              </Button>
            </StyledColumn>
            <StyledColumn columns='1/3'>
              <Button as={ButtonLink} to='/transfer/' color='green' ghost>
                Equipment Transfer Request
              </Button>
            </StyledColumn>
            <StyledColumn columns='1/3'>
              <Button as={ButtonLink} to='/loaner/' color='green' ghost>
                Equipment Loan Request
              </Button>
            </StyledColumn>
          </Grid>
        </Section>
      </Content>

      <LocationsSection />
    </Layout>
  )
}

const StyledColumn = styled(Column)`
  @media ${props => props.theme.mediaQuery.mediumDown} {
    margin-top: 20px;
  }
`

const ButtonLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`

export const pageQuery = graphql`
  {
    allSanityLocation(
      filter: { status: { ne: "permanently-closed" }, retailLocation: { eq: true } }
    ) {
      totalCount
    }
    heroImage: file(relativePath: { eq: "storefront.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default ContactPage
