import React, { useState } from 'react'
import { Column, Grid } from 'components/grid'
import Form from 'react-netlify-form'
import { Submit } from 'components/forms/elements/v2/submit'
import Email from 'components/forms/elements/email'
import Phone from 'components/forms/elements/phone'
import Input from 'components/forms/elements/input'
import Location from 'components/forms/elements/location'
import Select from 'components/forms/elements/select'
import Textarea from 'components/forms/elements/textarea'
import ResponseMessage from 'components/forms/elements/response-message'
import { useFormValidation } from 'hooks/use-form-validation'

const ContactForm = () => {
  const [valid, updateValidationSet] = useFormValidation({})
  const [started, setStarted] = useState(false)
  return (
    <Form
      name='Contact'
      recaptcha={{
        sitekey: process.env.GATSBY_RECAPTCHA_KEY,
        size: 'normal',
      }}
    >
      {({ loading, error, success, recaptcha, recaptchaError }) => (
        <div>
          {success && (
            <ResponseMessage
              message='Success!'
              messageBody='Your form has been submitted. Our team will be in touch with you soon!'
            />
          )}
          {error && (
            <ResponseMessage
              error={true}
              message='Error Occurred.'
              messageBody='There was an issue submitting your form. If problems persist please contact <a href="mailto:contactus@hutsoninc.com">our support team</a>.'
            />
          )}
          {recaptchaError && (
            <ResponseMessage
              error={true}
              message='ReCAPTCHA missing.'
              messageBody='Please check the reCAPTCHA box and try again.'
            />
          )}
          <div onFocus={() => setStarted(true)} role='none'>
            <Grid>
              <Column columns='1/2'>
                <Input
                  updateValidationSet={updateValidationSet}
                  label='First Name'
                  name='First Name'
                  validationError='Please enter a valid first name.'
                  required
                />
              </Column>
              <Column columns='1/2'>
                <Input
                  updateValidationSet={updateValidationSet}
                  label='Last Name'
                  name='Last Name'
                  validationError='Please enter a valid last name.'
                  required
                />
              </Column>
            </Grid>
            <Grid>
              <Column columns='2/3'>
                <Email updateValidationSet={updateValidationSet} required />
              </Column>
              <Column columns='1/3'>
                <Phone updateValidationSet={updateValidationSet} required />
              </Column>
            </Grid>
            <Grid>
              <Column columns='1/2'>
                <Location updateValidationSet={updateValidationSet} />
              </Column>
              <Column columns='1/2'>
                <Select
                  updateValidationSet={updateValidationSet}
                  label='Department'
                  name='Department'
                >
                  <option value=''>Select a department</option>
                  <option value='General Request'>General Request</option>
                  <option value='Sales'>Sales</option>
                  <option value='Parts'>Parts</option>
                  <option value='Service'>Service</option>
                  <option value='Marketing'>Marketing</option>
                  <option value='Careers'>Careers</option>
                </Select>
              </Column>
            </Grid>
            <Textarea
              updateValidationSet={updateValidationSet}
              label='Message'
              name='Message'
              validationError='Please enter a message.'
              required
            />
            <div className='recaptcha'>{recaptcha}</div>
            <Submit disabled={(!valid && started) || success} loading={loading} />
          </div>
        </div>
      )}
    </Form>
  )
}

export default ContactForm
